import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/board/:id",
      name: "board",
      component: () => import("./views/board/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/auth/login/index.vue"),
      meta: {
        authPage: true,
      },
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
