<template>
  <div
    id="app"
    :class="{
      'auth-page': $route.meta.authPage,
    }"
  >
    <HeaderComponent v-if="!$route.meta.authPage" />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
  </div>
</template>

<script>
import HeaderComponent from "components/HeaderComponent.vue";

export default {
  name: "App",
  components: { HeaderComponent },
  metaInfo: {
    title: "Система ведения проектов",
    titleTemplate: "%s | Comrades Dev",
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
