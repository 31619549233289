<template>
  <figure :class="`icon ${category}-${name}-icon`" v-if="!error">
    <InlineSvg :src="`/static/svg/${category}/${name}.svg`" @error="error = true" />
  </figure>
</template>
<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "IconComponent",
  props: {
    name: String,
    category: {
      type: String,
      default: "v-outline",
    },
  },
  components: {
    InlineSvg,
  },
  data: () => ({
    error: false,
  }),
};
</script>

<style lang="stylus">
.icon {
  display inline-flex
  align-items center
  justify-content center

  svg {
    width 100%
    height 100%
  }
}
</style>
