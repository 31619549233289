import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default function createStore(state) {
  return new Vuex.Store({
    state() {
      return (
        state || {
          users: [],
          boards: [],
          tasks: [],
          task: {
            open: false,
            data: null,
          },
          user: null,
          asideOpen: true,
        }
      );
    },
  });
}
