import PLATRUM_REQUEST from "@/graphql/PlatrumRequest.graphql";
import dayjs from "dayjs";

export default {
  install(Vue, db) {
    Vue.prototype.$platrum = {
      async query(app, url, body = {}, method = "get") {
        let { data } = await app.$apollo.mutate({
          mutation: PLATRUM_REQUEST,
          variables: {
            url: "comrades.platrum.ru" + url,
            body: JSON.stringify(body),
            method,
          },
        });
        return JSON.parse(data.PlatrumRequest.response);
      },
      async cache(app, url, ttl = 60 * 60, force) {
        if (app.$store.state.user) {
          return await new Promise((resolve, reject) => {
            db.all("SELECT * FROM cache ORDER BY created_at DESC", [], async (err, rows) => {
              if (err) {
                console.error(err);
                reject(err);
              }
              app.$platrum.set(app, rows, url, ttl, force).then(resolve);
            });
          });
        }
      },
      async cacheAll(app) {
        // Кэширование информации
        if (app.$store.state.user) {
          await new Promise((resolve, reject) => {
            db.all("SELECT * FROM cache ORDER BY created_at DESC", [], async (err, rows) => {
              if (err) {
                console.error(err);
                reject(err);
              }
              Promise.all([
                app.$platrum.set(app, rows, "/user/api/profile/list"),
                app.$platrum.set(app, rows, "/tasks/api/board/list"),
              ]).then(() => {
                resolve();
              });
            });
          });
        }
      },
      get(url) {
        return new Promise((resolve, reject) => {
          db.all(
            `SELECT * FROM cache WHERE url = '${url}' ORDER BY created_at DESC LIMIT 1`,
            [],
            (err, rows) => {
              if (err) {
                console.error(err);
                reject(err);
                return;
              }
              if (!(rows && rows[0])) {
                resolve([]);
                return;
              }
              resolve(JSON.parse(rows[0].data));
            }
          );
        });
      },
      async set(app, rows, url, ttl = 60 * 60 * 24 * 31, force) {
        await new Promise((resolve, reject) => {
          if (!force) {
            let cacheRecord = rows.find((r) => r.url === url);
            if (cacheRecord) {
              let endTime = dayjs(cacheRecord.created_at).add(cacheRecord.ttl, "second");
              if (endTime.diff(dayjs()) >= 0) {
                resolve();
                return;
              }
            }
          }
          app.$platrum.query(app, url).then(({ data }) => {
            db.run(
              `INSERT INTO cache(url, data, ttl) VALUES (?, ?, ?)`,
              [url, JSON.stringify(data), ttl],
              (err) => {
                if (err) {
                  console.error(err);
                  reject(err);
                }
                resolve();
              }
            );
          });
        });
      },
    };
  },
};
