<template>
  <header class="header">
    <div class="header__logo">
      <button
        type="button"
        class="header__menu"
        @click="$store.state.asideOpen = !$store.state.asideOpen"
        title="Фильтр проектов"
        v-if="$route.name === 'board' && this.$store.state.boards.length > 1"
      >
        <IconComponent name="menu-1" />
      </button>
      <img src="/static/svg/default/logo-inv.svg" alt="Comrades" />
      <span class="header__title" v-if="$route.name === 'board' && $store.state.board">
        {{ $store.state.board.name }}
      </span>
    </div>
    <div class="header__search" v-click-outside="hideSearch">
      <InputComponent title="Поиск" type="text" v-model="search" />
      <div class="search-list" v-if="searchList.length || searchLoading || search.length >= 3">
        <span v-if="search.length >= 3 && !searchList.length && !searchLoading">Задачи не найдены</span>
        <LoadingIndicator title="Загрузка" v-if="searchLoading" />
        <template v-if="searchList.length">
          <div class="search-list__title">Задачи {{ `(${searchList.length})` }}</div>
          <div class="search-list__links" v-for="(task, i) in searchList.slice(0, 10)" :key="i">
            <a :href="route({ name: 'board', params: { id: task.board.id } })" :key="i">
              <b>{{ task.board.name }}</b> {{ task.name }}
            </a>
          </div>
        </template>
      </div>
    </div>
    <div class="header__user" v-if="user">
      <img :src="userImage" :alt="userName" class="header__user-img" />
      <span class="header__user-name">{{ userName }}</span>
      <button type="button" class="header__user-logout" title="Выход" @click.prevent="logout">
        <IconComponent name="logout-1" />
      </button>
    </div>
  </header>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import ClickOutside from "vue-click-outside";
import LoadingIndicator from "components/LoadingIndicator.vue";
import { route } from "@/utils.js";

export default {
  name: "HeaderComponent",
  data: () => ({
    searchList: [],
    searchLoading: false,
    searchDebounce: null,
    search: "",
  }),
  watch: {
    search(val) {
      if (val && val.length >= 3) {
        this.searchLoading = true;
        clearTimeout(this.searchDebounce);
        this.searchDebounce = setTimeout(() => {
          this.$platrum.query(this, "/tasks/api/task/list?search=" + val).then(({ data }) => {
            let panels = [];
            this.$store.state.boards.forEach((b) => {
              b.panels.forEach((p) => {
                panels.push({
                  board: b,
                  panel: p.id,
                });
              });
            });
            this.searchList = data
              .map((t) => {
                t.board = panels.find((p) => t.board_panel_id === p.panel)?.board;
                return t;
              })
              .filter((t) => !!t.board);
            this.searchLoading = false;
          });
        }, 500);
        return;
      }
      this.hideSearch();
    },
  },
  methods: {
    route,
    hideSearch() {
      this.search = "";
      this.searchList = [];
    },
    logout() {
      if (confirm("Вы уверены?")) {
        fetch("/token", {
          method: "post",
          body: JSON.stringify({
            token: undefined,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          location.reload();
        });
      }
    },
  },
  computed: {
    userImage() {
      let img = this.$options.filters.img;
      return this.user.platrum ? img(this.user.platrum.avatar, 100, 100) : img(null);
    },
    user() {
      return this.$store.state.user;
    },
    userName() {
      let user = this.$store.state.user;
      let surname = user.surname || "";
      let name = user.name ? user.name.substring(0, 1) : undefined;
      let patronymic = user.patronymic ? user.patronymic.substring(0, 1) : undefined;
      return `${surname} ${name ? " " + name + "." : ""} ${patronymic ? " " + patronymic + "." : ""}`;
    },
  },
  components: { LoadingIndicator, InputComponent, IconComponent },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.search-list {
  position absolute
  left 0
  right 0
  bottom 0
  transform translateY(100%)
  z-index 999
  padding 16px
  max-height 250px
  overflow-y auto
  width 100%
  border-radius var(--radius)
  box-shadow 0 15px 30px rgba(0, 0, 0, 0.15)
  background var(--white)
  display grid
  justify-content start
  grid-gap 10px
  scrollbar-width thin
  scrollbar-color rgba(0, 0, 0, 0.3) transparent

  &::-webkit-scrollbar {
    width 8px
    height 8px
  }

  &::-webkit-scrollbar-track {
    background transparent
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color rgba(0, 0, 0, 0.3)
    border-radius 3px
    border none
  }

  .loading-indicator {
    svg circle {
      stroke var(--dark)
    }
  }

  &__title {
    font-size: 1.375rem;
    color: var(--dark);
  }

  &__links {
    display grid
    grid-gap 8px

    a {
      color var(--blue-900)
    }
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-content: center;
  gap: 34px;
  justify-content space-between
  background var(--blue-100)
  padding 0 16px
  height 70px

  &__search {
    position relative
    display inline-flex

    .input__container {
      width 100%
    }
  }

  &__title {
    font-size 1.75rem
    font-weight 500
    color var(--white)
  }

  &__menu {
    background none
    border none
    cursor pointer
    padding 6px
    display inline-flex

    &:hover {
      background var(--blue-500)
      border-radius var(--radius)
    }

    .icon {
      width 24px
      height 24px

      svg path {
        fill var(--white)
      }
    }
  }

  &__logo {
    display inline-flex
    gap 10px
    align-items center

    img {
      width 50px
      height 50px
      object-fit cover
      object-position center
    }
  }

  &__user {
    display flex
    align-items center
    gap 8px
    height 100%
    cursor pointer
  }

  &__user-logout {
    border none
    background none
    cursor pointer
    display inline-flex
    align-items center
    margin-left 6px
    padding 6px

    &:hover {
      background var(--blue-500)
      border-radius var(--radius)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--white)
      }
    }
  }

  &__user-img {
    width 40px
    height 40px
    object-fit cover
    object-position center
    border-radius 100%
    background var(--white)
  }

  &__user-name {
    color var(--white)
  }
}
</style>
