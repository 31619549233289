import "es6-promise/auto";
import createApp from "./app";

const app = createApp(window.__INITIAL_STATE__._env);
const $store = app.$store;
const $router = app.$router;

if (window.__INITIAL_STATE__) {
  $store.replaceState(window.__INITIAL_STATE__);
  $store.state._window_size = window.innerWidth;
  $store.state._env = window.__INITIAL_STATE__._env || {};
  $store.state.apollo_token = window.__INITIAL_STATE__.apollo_token || undefined;
}

$router.onReady(() => {
  $router.beforeEach((to, from, next) => {
    if (to.meta.authRequired && !$store.state.user) {
      next(false);
    } else {
      next();
    }
  });
  $router.beforeResolve((to, from, next) => {
    const matched = $router.getMatchedComponents(to);
    const asyncDataHooks = matched.map((c) => c.asyncData).filter((_) => _);
    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook(app)))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

// if (location.protocol === "https:" && navigator.serviceWorker) {
//   navigator.serviceWorker.register("/service-worker.js").then((value) => {
//     console.log(value);
//   });
// }
