export function resetErrors() {
  Object.keys(this.form).forEach((key) => {
    this.form[key].errors = [];
  });
}

export function route(route) {
  if (route) {
    return this.$router.resolve(route).href;
  }
  return "";
}

export async function getBoards(app) {
  await app.$platrum.get("/tasks/api/board/list").then((data) => {
    let user = app.$store.state.user;
    if (user.boards) {
      app.$store.state.boards = data.filter((b) => user.boards.findIndex((ub) => ub.board_id === b.id) >= 0);
    } else {
      app.$store.state.boards = [];
    }
  });
}

export function parseGqlErrors(graphQLErrors) {
  graphQLErrors.forEach((err) => {
    if (err.extensions.category === "validation") {
      Object.keys(err.extensions.validation).forEach((key) => {
        if (this.form[key]) {
          this.form[key].errors.push(err.extensions.validation[key][0]);
        }
      });
    }
  });
}
